import { Button, Card, CardHeader, Grid, Paper, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import ProductCard from '../../components/Card/ProductCard'
import MoneyInput from '../../components/Input/MoneyInput'
import PhoneNumberInput from '../../components/Input/PhoneNumberInput'
import { CartContext } from '../../contexts/CartContext'

const getItem = id => ({
	itemID: id,
	itemName: `name${id}`,
	storeID: id,
	storeName: `storeName${id}`,
	storeDescription: `storeDescription${id} storeDescription${id} storeDescription${id} storeDescription${id} storeDescription${id} \
	storeDescription${id} storeDescription${id} storeDescription${id} storeDescription${id} storeDescription${id}`,
	itemDescription: `itemDescription${id} itemDescription${id} itemDescription${id} itemDescription${id} itemDescription${id} \
	itemDescription${id} itemDescription${id} itemDescription${id} itemDescription${id} itemDescription${id}`,
	amount: 2
}) 

export default function Home(props) {
	const { addItem } = useContext(CartContext)
	return <Paper square>
		<CardHeader
			title="Card"
		/>
		<Grid container justify="space-evenly">
			{Array(20).fill(0).map((_, index) => <Grid item key={index} style={{ padding: '8px 0' }}>
				<ProductCard item={getItem(index + 1)} />
			</Grid>)}
		</Grid>
	</Paper>
}