import { AppBar, Checkbox, Container, Dialog, FormControlLabel, Grid, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext, useMemo, useState } from 'react'
import { CartContext } from '../../contexts/CartContext';
import { useHistory } from 'react-router-dom';
import { List } from '@material-ui/icons';
import CartStore from './CartStore';
import { copyDeep } from '../../ctrl/cal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CartDialog(props) {
  const { visible, setVisible, cart } = useContext(CartContext)
  
  const history = useHistory()
  const [selectedList, setSelectedList] = useState([])

  const storeIDSelectedList = useMemo(() => selectedList.map(({ storeID }) => storeID), [selectedList])
  const allChecked = useMemo(() => cart.length !== 0 && cart.length === selectedList.length, [selectedList, cart])

  const handleAll = e => {
    const checked = e.target.checked
    if (checked) {
      setSelectedList(copyDeep(cart))
    } else {
      setSelectedList([])
    }
  }

  const handleStore = store => e => {
    if (e.target.checked) {
      setSelectedList([ ...selectedList, store ])
    } else {
      setSelectedList([ ...selectedList.filter(selected => selected.storeID !== store.storeID) ])
    }
  }

  return <Dialog fullScreen open={visible} onClose={e => setVisible(false)} TransitionComponent={Transition}>
  <AppBar style={{ position: 'relative' }}>
    <Toolbar>
      <IconButton color="inherit" edge="start" onClick={e => history.goBack()} aria-label="close">
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        Cart
      </Typography>
      <IconButton color="inherit" edge="end" onClick={e => setVisible(false)}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
  <Container>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecked}
              onChange={handleAll}
              name="cart-check-all"
              color="secondary"
            />
          }
          label={"All"}
        />
      </Grid>
      {cart.map((store, key) => <Grid
        key={key}
        item
      >
        <CartStore
          store={store}
          checked={storeIDSelectedList.includes(store.storeID)}
          onChange={handleStore(store)}
        />
      </Grid>)}
    </Grid>
  </Container>
  </Dialog>
}