import { useMediaQuery } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import React, { createContext, useReducer, useEffect, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { login, logout } from '../actions/logging';
import { getCookieOrDefault, setCookie } from '../ctrl/cookie';
import { useStyles } from './styles';
import queryString from 'query-string';

const state = {
  drawer: {
    anchor: 'left',
    open: false
  },
  setting: getCookieOrDefault('setting', { theme: 'dark' }),
  logging: getCookieOrDefault('logging', {})
}

const AppContext = createContext(state);

const settingReducer = (state, action) => {
  let newState = action?.payload || {};
  switch (action.type) {
    case 'TOGGLE_THEME':
      newState = { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' }
      break;
    case 'SET_VALUE':
      newState = { ...state, ...newState }
      break;
    default:
      newState = state
      break;
  }
  setCookie('setting', newState)
  return newState
}

const loggingReducer = async (state, action) => {
  let newState = action?.payload || {};
  switch (action.type) {
    case 'LOG_IN':
      console.log("signin22", newState)
      newState = await login(state)
      console.log("signin2", newState)
      break;
    case 'LOG_OUT':
      newState = await logout(state)
      break;
    case 'SET_VALUE':
      newState = { ...state, ...newState }
      break;
    default:
      newState = state
      break;
  }
  console.log("signin2", newState)
  setCookie('logging', newState)
  return Promise.resolve(newState)
}

const drawerReducer = (state, action) => {
  let newState = action?.payload || {};
  switch (action.type) {
      case 'TOGGLE_DRAWER':
          newState = { ...state, open: !state.open }
          return newState;
      case 'SET_VALUE':
          return { ...state, ...newState };
      default:
          return state;
  }
}

const AppProvider = props => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const confirm = useConfirm()
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const query = useMemo(() => location.search ? queryString.parse(location.search) : {}, [location.search])
  const setQuery = newQuery => {
    history.push({
      ...location,
      search: queryString.stringify(newQuery),
    })
  }

  const [drawer, actionDrawer] = useReducer(drawerReducer, state.drawer)
  const [setting, actionSetting] = useReducer(settingReducer, state.setting)
  const [logging, actionLogging] = useReducer(loggingReducer, state.logging)

  const toggleDrawer = () => actionDrawer({ type: 'TOGGLE_DRAWER' })
  const toggleTheme = () => actionSetting({ type: 'TOGGLE_THEME' })
  const signin = async () => {
    try {
      await actionLogging({ type: 'LOG_IN' })
      console.log(logging)
      console.log("signin3")
    } catch (error) {
      console.error(error)
    }
  }
  const signout = async () => {
    try {
      await confirm({ description: 'Would you like to log out?' })
      await actionLogging({ type: 'LOG_OUT' })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const preThemDark = setting.theme === 'dark'
    if (!preThemDark === !prefersDarkMode) {
      actionSetting({
        type: 'SET_VALUE',
        payload: {
          theme: prefersDarkMode ? 'dark' : 'light'
        }
      })
    }
  }, [prefersDarkMode])

  return <AppContext.Provider
  value={{
    drawer,
    actionDrawer,
    toggleDrawer,
    setting,
    actionSetting,
    toggleTheme,
    logging,
    signin,
    signout,
    classes,
    query,
    setQuery
  }}
>
  {props.children}
</AppContext.Provider>
}

export { AppContext, AppProvider };