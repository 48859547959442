import { Avatar, Card, CardHeader, CardMedia, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useContext } from 'react'
import { CartContext } from '../../contexts/CartContext'
import { showMoney } from '../../ctrl/format'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      width: '160px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      height: '172px',
      width: '306px',
    }
  },
  header: {
    [theme.breakpoints.up('sm')]: {
      width: '126px',
      paddingLeft: '8px'
    }
  },
  media: {
    [theme.breakpoints.down('xs')]: {
      height: '160px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '172px',
    }
  },
  mediaAvatar: {
    [theme.breakpoints.down('xs')]: {
      height: '160px',
      width: '160px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '172px',
      width: '172px',
    }
  },
  description: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordWrap: "break-word",
    overflow: "hidden"
  }
}));

export default function ProductCard({ item, ...props }) {
  const classes = useStyles();
	const { addItem } = useContext(CartContext)
  const downXS = useMediaQuery(theme => theme.breakpoints.down("xs"))

  const renderMHeader = <CardHeader
    className={classes.header}
    title={item.itemName}
    subheader={showMoney(item.amount)}
    titleTypographyProps={{
      variant: 'subtitle1'
    }}
    subheaderTypographyProps={{
      align: 'right',
      color: 'secondary'
    }}
  />

  const renderHeader = <Grid container direction="column" justify="space-between" className={classes.header}>
    <Grid item>
      <Typography variant="subtitle1">
        {item.itemName}
      </Typography>
      <Typography variant="subtitle2" className={classes.description} color="textSecondary">
        {item.itemDescription}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1" align="right" color="secondary">
        {showMoney(item.amount)}
      </Typography>
    </Grid>
  </Grid>

  return <Card
    className={classes.root}
    onClick={e => addItem(item, 1)}
    elevation={0}
    square
  >
    <CardMedia className={classes.media}>
      <Avatar variant="rounded" className={classes.mediaAvatar}/>
    </CardMedia>
    {downXS ? renderMHeader : renderHeader}
  </Card>
}