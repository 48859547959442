import moment from "moment"
import { addDelay } from "../ctrl/timeout"

export const login = async (logging) => {
  try {
    await addDelay(2000)
    return Promise.resolve({
      ...logging,
      authorized: true,
      exp: moment().format()
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const logout = async (logging) => {
  try {
    await addDelay(2000)
    return Promise.resolve({
      ...logging,
      authorized: false,
      exp: undefined
    })
  } catch (error) {
    return Promise.reject(error)
  }
}