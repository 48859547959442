import Cookies from "js-cookie"

export const getCookieOrDefault = (name, defaultValue) => {
  let value = Cookies.get(name)
  // Cookies.remove(name)
  return value ? JSON.parse(value) : defaultValue
}

export const setCookie = (name, value) => {
  if (Array.isArray(value) || typeof value === 'object') {
    Cookies.set(name, JSON.stringify(value))
  } else if (value || value === 0) {
    Cookies.set(name, value)
  }
}