import { useMediaQuery } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { createContext, useReducer, useEffect } from 'react'
import { useWait } from 'react-wait';
import { login, logout } from '../actions/logging';
import { getCookieOrDefault, setCookie } from '../ctrl/cookie';

const state = getCookieOrDefault('logging', {})

const LoggingContext = createContext(state);

const loggingReducer = (state, action) => {
  let newState = action?.payload || {};
  switch (action.type) {
    case 'SET_VALUE': {
      newState = { ...state, ...newState }
      break;
    }
    default:
      newState = state
      break;
  }
  setCookie('logging', newState)
  return newState
}

const LoggingProvider = props => {
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const { startWaiting, endWaiting } = useWait()
  
  const [logging, actionLogging] = useReducer(loggingReducer, state)

  const signin = async () => {
    try {
      startWaiting('signin')

      const newLogging = await login(logging)
      actionLogging({ type: 'SET_VALUE', payload: newLogging })

      endWaiting('signin')
    } catch (error) {
      console.error(error)
      endWaiting('signin')
    }
  }
  const signout = async () => {
    try {
      startWaiting('signout')

      await confirm({ description: 'Would you like to log out?' })
      const newLogging = await logout(logging)
      actionLogging({ type: 'SET_VALUE', payload: newLogging })

      endWaiting('signout')
    } catch (error) {
      console.error(error)
      endWaiting('signout')
    }
  }
  const signon = async () => {
    try {
      startWaiting('signon')

      enqueueSnackbar("Where're you come from?", {
          variant: "warning"
      })
      
      endWaiting('signon')
    } catch (error) {
      console.error(error)
      endWaiting('signon')
    }
  }

  return <LoggingContext.Provider
  value={{
    logging,
    signin,
    signout,
    signon
  }}
>
  {props.children}
</LoggingContext.Provider>
}

export { LoggingContext, LoggingProvider };