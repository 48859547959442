import { Box, Button, Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useWait } from 'react-wait'
import ButtonProgress from '../../components/Progress/ButtonProgress'
import { LoggingContext } from '../../contexts/LoggingContext'

export default function SignIn(props) {
  const { signin } = useContext(LoggingContext)
  const { isWaiting } = useWait()
  return <Grid container justify="center">
    <Grid item>
      <Box position="relative">
        <Button onClick={signin} disabled={isWaiting('signin')}>
          Sign In
        </Button>
        <ButtonProgress loading={isWaiting('signin')} />
      </Box>
    </Grid>
  </Grid>
}