import { Grid, Avatar, Typography, IconButton, Box, Checkbox, FormControlLabel, CardHeader, withWidth } from '@material-ui/core'
import React, { memo, useContext } from 'react'
import { CartContext } from '../../contexts/CartContext'
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { useWait } from 'react-wait';
import ButtonProgress from '../Progress/ButtonProgress';
import { isEqualForNest } from '../../ctrl/cal';
import { showMoney } from '../../ctrl/format';
import { AppContext } from '../../contexts/AppContext';

const areEqual = (prev, next) => prev.checked === next.checked
  && prev.onChange === next.onChange
  && isEqualForNest(prev.item, next.item)

function CartItem({ item, checked, onChange, width, ...props }) {
  const { addItem, subtractItem } = useContext(CartContext)
  const { classes } = useContext(AppContext)
  const { isWaiting } = useWait()
  const renderCountItem = <>
    <Grid item>
      <Box position="relative">
        <IconButton edge="start" color="secondary" onClick={e => subtractItem(item, 1)} disabled={isWaiting('cartAction')}>
          <RemoveIcon />
        </IconButton>
        <ButtonProgress loading={isWaiting('cartAction')}/>
      </Box>
    </Grid>
    <Grid item>
      <Typography variant="h6">
        {item.sum}
      </Typography>
    </Grid>
    <Grid item>
      <Box position="relative">
        <IconButton color="secondary" onClick={e => addItem(item, 1)} disabled={isWaiting('cartAction')}>
          <AddIcon />
        </IconButton>
        <ButtonProgress loading={isWaiting('cartAction')}/>
      </Box>
    </Grid>
  </>
  const renderMoney = <Typography variant="subtitle1" color="secondary" align={width === 'xs' ? 'left' : 'right'}>
    {showMoney(item.amount * item.sum)}
  </Typography>

  return <Grid container item alignItems="center">
    <Grid item style={{ width: '142px' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={item.itemID}
            color="secondary"
          />
        }
        label={<Avatar variant="rounded" className={classes.img100} />}
      />
    </Grid>
    <Grid container direction="column" item style={{ width: `calc(${width === 'xs' ? '100%' : '50%'} - 142px)` }}>
      <Grid item>
        <Typography variant="subtitle1">
          {item.itemName}
        </Typography>
      </Grid>
      {width === 'xs' && <Grid container item alignItems="center">
        {renderCountItem}
      </Grid>}
      {width === 'xs' && <Grid item>
        {renderMoney}
      </Grid>}
    </Grid>
    <Grid container item xs={6} sm={2} justify="flex-end" alignItems="center">
      {width !== 'xs' && renderCountItem}
    </Grid>
    <Grid item xs={6} sm={4}>
      {width !== 'xs' && renderMoney}
    </Grid>
  </Grid>
}
export default memo(withWidth()(CartItem), areEqual)
