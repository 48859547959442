import { Avatar, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, ListItem } from '@material-ui/core'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { isEqualForNest } from '../../ctrl/cal'
import CartItem from './CartItem'

const areEqual = (prev, next) => prev.checked === next.checked
  && prev.onChange === next.onChange
  && isEqualForNest(prev.store, next.store)

function CartStore({ checked, store, onChange, ...props }) {
  const [selectedList, setSelectedList] = useState([])

  const itemIDSelectedList = useMemo(() => selectedList.map(({ itemID }) => itemID), [selectedList])
  const storeChecked = useMemo(() => store.items.length !== 0 && store.items.length === selectedList.length, [selectedList, store.items])

  const handleStore = e => {
    onChange(e)
    if (e.target.checked) {
      setSelectedList([ ...store.items ])
    } else {
      setSelectedList([])
    }
  }

  const handleItem = item => e => {
    let newList = []
    if (e.target.checked) {
      newList = [ ...selectedList, item ]
    } else {
      newList = [ ...selectedList.filter(selected => selected.itemID !== item.itemID) ]
    }
    if (newList.length === store.items.length) {
      onChange(e)
    } else if (selectedList.length === store.items.length && newList.length !== store.items.length) {
      onChange(e)
    }
    setSelectedList(newList)
  }

  useEffect(() => {
    if (checked !== storeChecked) {
      setSelectedList(checked
        ? [ ...store.items ]
        : [])
    }
  }, [checked])

  return <Card raised style={{ padding: '8px' }}>
    <ListItem>
      <FormControlLabel
        control={
          <Checkbox
            checked={storeChecked}
            onChange={handleStore}
            name={store.storeID}
            color="secondary"
          />
        }
        label={<CardHeader
          avatar={
            <Avatar />
          }
          title={store.storeName}
          style={{ minWidth: 0 }}
        />}
      />
    </ListItem>
    <Divider/>
    <CardContent>
      {store.items.map((item, key) => <CartItem
        key={key}
        checked={itemIDSelectedList.includes(item.itemID)}
        item={item}
        onChange={handleItem(item)}
      />)}
    </CardContent>
  </Card>
}

export default memo(CartStore, areEqual)