import { faSignInAlt, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Divider, Grid, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Switch, Typography } from '@material-ui/core';
import { Drawer, Box, List } from '@material-ui/core'
import { Home as HomeIcon, Brightness4 as Brightness4Icon } from '@material-ui/icons'
import React from 'react'
import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useWait } from 'react-wait';
import { AppContext } from '../../contexts/AppContext'
import { LoggingContext } from '../../contexts/LoggingContext';
import ButtonProgress from '../Progress/ButtonProgress';
const DrawerList = () => {
  const { setting, toggleTheme } = useContext(AppContext)
  const { logging, signout } = useContext(LoggingContext)
  const { isWaiting } = useWait()
  const location = useLocation()

  return (
  <Box
    width="250px"
    height="100%"
    minHeight="400px"
  >
    <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
      <Grid item>
        <List>
          <ListItem>
            <Typography variant="subtitle1">
              Menu
            </Typography>
          </ListItem>
          {logging.authorized
          && <ListItem button component={Link} to="/profile" selected={location.pathname.includes('/profile')}>
            <ListItemAvatar >
              <Avatar />
            </ListItemAvatar>
            <ListItemText primary="Profile"/>
          </ListItem>}
          <ListItem button component={Link} to="/" selected={location.pathname === '/' || location.pathname.includes('/home')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home"/>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem>
            <Typography variant="subtitle1">
              Setting
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Brightness4Icon />
            </ListItemIcon>
            <ListItemText primary={
                setting.theme === 'dark'
                ? 'Dark'
                : 'Light'
              }
            />
            <Switch checked={setting.theme !== 'dark'} onChange={() => toggleTheme()}/>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <Divider />
        <List>
          {logging.authorized
          ? <Box position="relative" >
            <ListItem button onClick={signout} disabled={isWaiting('signout')}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </ListItemIcon>
              <ListItemText primary="Sign Out"/>
            </ListItem>
            <ButtonProgress loading={isWaiting('signout')}/>
          </Box>
          : <><ListItem button component={Link} to="/signin" selected={location.pathname.includes('/signin')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSignInAlt} />
            </ListItemIcon>
            <ListItemText primary="Sign In"/>
          </ListItem>
          <ListItem button component={Link} to="/signon" selected={location.pathname.includes('/signon')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUserPlus} />
            </ListItemIcon>
            <ListItemText primary="Sign On"/>
          </ListItem>
          </>}
        </List>
      </Grid>
    </Grid>
  </Box>
)
};

export default function AppDrawer(props) {
  const { drawer, toggleDrawer } = useContext(AppContext)
  return <Drawer onClose={() => toggleDrawer(false)} {...drawer}>
    <DrawerList />
  </Drawer>
}