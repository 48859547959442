import { Backdrop, makeStyles, useScrollTrigger, Zoom } from '@material-ui/core'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import clsx from 'clsx'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
  topRight: {
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  bottomRight: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fixed: {
    position: 'fixed'
  },
  absolute: {
    position: 'absolute'
  },
}))

export default function MySpeedDial({ position, mode, direction, actions, ...props }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const handleClose = e => {
    setOpen(false)
  }
  const handleOpen = e => {
    setOpen(true)
  }
  const handleActionClick = callback => {
    callback()
    setOpen(false)
  }
  return <>
    <Backdrop open={open} style={{ zIndex: 1 }}/>
    <Zoom in={trigger}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={clsx`${classes[position || 'absolute']} ${classes[mode || 'topRight']}`}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction || 'down'}
        {...props}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={e => handleActionClick(action.onClick)}
          />
        ))}
      </SpeedDial>
    </Zoom>
    
  </>
}